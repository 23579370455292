import {
  useGetAppointedKeyCollectionDateLazyQuery,
  useGetKeyCollectionAvailableDateLazyQuery,
  useGetKeyCollectionAvailableTimeLazyQuery,
  useSalesAccountLazyQuery,
  useUpdateKeyCollectionDateMutation,
} from "../../../../generated/graphql";
import SimpleDialog from "../../../../components/Dialog/SimpleDialog";
import { ColumnFlex } from "../../../../components/LayoutX/Flex/ColumnFlex";
import { useEffect, useState } from "react";
import TextX from "../../../../components/general/TextX";
import { RowFlex } from "../../../../components/LayoutX/Flex/RowFlex";
import { Button } from "ui-neumorphism";
import { Autocomplete, TextField } from "@mui/material";
import { useSnackbar } from "react-simple-snackbar";
import moment from "moment";
import { cardChangeColor } from "../../../context/CardContext";

type KeyCollectionDialogProps = {
  unitId: string;
  saleId: string;
  projectId: string;
  visible: boolean;
  onClose: () => void;
  onResetKeyCollectionDateTime: () => void;
};

const KeyCollectionDialog: React.FC<KeyCollectionDialogProps> = ({
  unitId,
  saleId,
  projectId,
  visible,
  onClose,
  onResetKeyCollectionDateTime,
}) => {
  const [openSnackbar] = useSnackbar();
  const { cardColor } = cardChangeColor();

  const [appointedDate, setAppointedDate] = useState<string>(null);
  const [selectedAvailableDate, setSelectedAvailableDate] =
    useState<string>(null);
  const [selectedAvailableTime, setSelectedAvailableTime] =
    useState<string>(null);

  const [isValidDate, setIsValidDate] = useState<boolean>(true);
  const [isValidTime, setIsValidTime] = useState<boolean>(true);
  const [shouldRunEffect, setShouldRunEffect] = useState(false);
  const [availableDate, setAvailableDate] = useState(null);
  const [selectedDisplayDate, setSelectedDisplayDate] = useState<string>(null);

  const [getAppointedDate, { data: appointedDateResult }] =
    useGetAppointedKeyCollectionDateLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const formatDate = moment(data?.getAppointedKeyCollectionDate).format(
          "dddd, MMMM Do YYYY, h:mm:ss a"
        );
        setAppointedDate(formatDate);
      },
    });
  const [getAvailableDate, { data: data }] =
    useGetKeyCollectionAvailableDateLazyQuery({
      onCompleted: (data) => {
        let dropdownDate = data.getKeyCollectionAvailableDate?.map((y) => {
          const formattedDate = moment.utc(y).format("DD-MM-YYYY");

          return formattedDate;
        });

        setAvailableDate(dropdownDate);
      },
    });
  const [getAvailableTime, { data: availableTimeList }] =
    useGetKeyCollectionAvailableTimeLazyQuery({});
  const [updateKeyCollectionDate] = useUpdateKeyCollectionDateMutation({
    onCompleted: (data) => {
      if (data?.updateKeyCollectionDate) {
        openSnackbar("Key collection updated successfully");
        setShouldRunEffect(true);
        handleClose();
      }
    },
  });
  const [getSalesAccount] = useSalesAccountLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!shouldRunEffect) {
      getAppointedDate({
        variables: {
          projectId,
          unitId,
        },
      });

      getAvailableDate({
        variables: {
          projectId,
          saleId,
          unitId,
        },
      });
    } else {
      getAppointedDate({
        variables: {
          projectId,
          unitId,
        },
      });

      getSalesAccount();

      setShouldRunEffect(false);
    }
  }, [shouldRunEffect]);

  useEffect(() => {
    if (!selectedAvailableDate) return;
    getAvailableTime({
      variables: {
        projectId,
        saleId,
        unitId,
        collectionDate: selectedAvailableDate,
      },
    });
  }, [selectedAvailableDate]);

  const handleClose = () => {
    if (onResetKeyCollectionDateTime) {
      onResetKeyCollectionDateTime(); // Reset the date and time when dialog closes
      setSelectedDisplayDate(null);
      setSelectedAvailableDate(null);
      setSelectedAvailableTime(null);
      setIsValidDate(true);
      setIsValidTime(true);
    }
    onClose();
  };

  const handleChangeDate = (value) => {
    if (!value) {
      setSelectedAvailableTime(null);
      setIsValidDate(false);
    } else {
      setSelectedAvailableTime(null);

      setIsValidDate(true);
    }

    const parsedDate = moment(value, "DD-MM-YYYY", true);

    if (!parsedDate.isValid()) {
      return;
    }
    const formattedDateString = parsedDate.format("YYYY-MM-DD");

    setSelectedDisplayDate(value);
    setSelectedAvailableDate(formattedDateString);
  };

  const handleChangeTime = (value) => {
    if (!value) {
      setIsValidTime(false);
    } else {
      setIsValidTime(true);
    }

    setSelectedAvailableTime(value);
  };

  const concatenateDateTime = () => {
    let date = moment.utc(selectedAvailableDate).format("YYYY-MM-DD");
    let time = moment(selectedAvailableTime, ["h:mm A"]).format("HH:mm:ss");

    let dateTime = moment(date + " " + time).format();

    return new Date(dateTime);
  };

  const handleConfirm = () => {
    if (!selectedAvailableDate) {
      setIsValidDate(false);
    }

    if (!selectedAvailableTime) {
      setIsValidTime(false);
    }

    if (!selectedAvailableDate || !selectedAvailableTime) {
      openSnackbar("Please fill in all required fields.");
      return;
    }

    const concatDateTime = concatenateDateTime();

    updateKeyCollectionDate({
      variables: {
        projectId,
        saleId,
        unitId,
        scheduledDateTime: concatDateTime,
      },
    });
  };

  return (
    <SimpleDialog visible={visible} onClose={null} background={cardColor}>
      <ColumnFlex gap="16px">
        <span className="fw-700 fs-16 color-text text-with-shadow">
          Key Collection Date
        </span>
        <span className="fs-12 color-text fw-500">
          Appointment Date:{" "}
          <span className="color-text-light-blue fw-700">
            {appointedDate || "-"}
          </span>
        </span>

        {/* Select Date */}
        <Autocomplete
          options={availableDate}
          value={selectedDisplayDate}
          onChange={(e, value) => handleChangeDate(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Date"
              required
              error={!isValidDate}
              helperText={!isValidDate ? `Date is required` : ""}
              InputProps={{
                ...params.InputProps,
                style: { cursor: "default" },
              }}
              inputProps={{
                ...params.inputProps,
                style: { pointerEvents: "none" },
              }}
            />
          )}
        />

        {/* Select Time */}
        <Autocomplete
          options={availableTimeList?.getKeyCollectionAvailableTime}
          value={selectedAvailableTime}
          onChange={(e, value) => handleChangeTime(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Time Slot"
              required
              error={!isValidTime}
              helperText={!isValidTime ? `Time is required` : ""}
              disabled={!selectedAvailableDate}
              InputProps={{
                ...params.InputProps,
                style: { cursor: "default" },
              }}
              inputProps={{
                ...params.inputProps,
                style: { pointerEvents: "none" },
              }}
            />
          )}
          disabled={!selectedAvailableDate}
        />

        <RowFlex
          gap="14px"
          fullWidth
          mainAxisAlignment="flex-end"
          className="disable-full-uppercase"
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button className={"color-text-light-blue"} onClick={handleConfirm}>
            Confirm
          </Button>
        </RowFlex>
      </ColumnFlex>
    </SimpleDialog>
  );
};

export default KeyCollectionDialog;
